import React from "react";

const Changeyourselfensupport: React.FC = () => {
  return (
    <div className="support">
      <h1>Support</h1>
      <p>Need help? We're here to assist you! You can contact us using the information below:</p>
      <ul>
        <li>Email: support@changeyourself.com</li>
        <li>Working Hours: Monday - Friday, 09:00 - 18:00</li>
      </ul>
      <h2>FAQ</h2>
      <p>For quick solutions, visit our <a href="/faq">Frequently Asked Questions</a> page.</p>
    </div>
  );
};

export default Changeyourselfensupport;
