import React from 'react';
import '../privacy/privacy.css';

const ChangeYourselfenPrivacy: React.FC = () => {
  return (
    <div className="privacy-policy">
      <div className="container">
        <h1>Privacy Policy</h1>
        <p>
          This privacy policy explains how <strong>Change Yourself Funny - AI</strong> protects user privacy. Our privacy policy is designed to comply with the requirements of the Google Play Store and ensure user security.
        </p>

        <p>
          <strong>About the App</strong>
        </p>
        <p>
          <strong>Change Yourself Funny - AI</strong> is an entertaining app that allows users to upload photos and transform them into specific categories using artificial intelligence. Users can transform their uploaded photos into categories like doctor, soldier, or any other profession. The app uses AI to transform the uploaded photo into the selected category, allowing the user to see themselves as a doctor or in other professions.
        </p>

        <p>
          <strong>Collected Data</strong>
        </p>
        <p>
          The <strong>Change Yourself Funny - AI</strong> app collects user photos and personal information, but only for the functionality of the app. The app does not share your personal data with third parties and uses it solely for AI transformations.
        </p>

        <p>
          <strong>Data Usage</strong>
        </p>
        <p>
          The collected data is used to provide photo transformations and enhance the user experience. Photos and transformation data are processed strictly in accordance with the app's features and cannot be used to identify individuals.
        </p>

        <p>
          <strong>Data Sharing</strong>
        </p>
        <p>
          <strong>Change Yourself Funny - AI</strong> does not share, sell, or rent user data to third parties. However, in case of legal requirements, data may be shared with legal authorities.
        </p>

        <p>
          <strong>Data Retention and Deletion</strong>
        </p>
        <p>
          User photos and transformation data are stored only for in-app functionality. User data is securely deleted once the functionality is no longer needed or when it is no longer required.
        </p>

        <p>
          <strong>Privacy Policy Changes</strong>
        </p>
        <p>
          This privacy policy may be updated from time to time. Any changes will be announced on this page. Users are encouraged to check the policy regularly.
        </p>

        <p>
          <strong>Contact</strong>
        </p>
        <p>
          If you have any questions regarding the privacy policy, please contact us using the information below:
        </p>
        <p>
          <strong>Contact Email:</strong> selmanatabek@hotmail.com
        </p>
      </div>
    </div>
  );
};

export default ChangeYourselfenPrivacy;
