import React from 'react';
import '../privacy/privacy.css';

const Changeyourselftrprivacyeula: React.FC = () => {
  return (
    <div className="privacy-policy">
      <div className="container">
        <h1>Son Kullanıcı Lisans Sözleşmesi (EULA)</h1>
        
        <p>
          Bu Son Kullanıcı Lisans Sözleşmesi (bundan sonra "Sözleşme" olarak anılacaktır), <strong>Change Yourself Funny - AI</strong> uygulamasının kullanımına ilişkin olarak sizinle (<strong>Kullanıcı</strong>, <strong>Siz</strong> veya <strong>Hesap Sahibiniz</strong>) arasında yapılan yasal bir anlaşmadır. Uygulamayı indirerek, yükleyerek veya kullanarak bu sözleşmenin şartlarını kabul etmiş olursunuz.
        </p>

        <p>
          <strong>1. Lisans Verme</strong><br />
          Şirket, size sadece kişisel, ticari olmayan bir kullanım amacıyla ve bu Sözleşme ile belirlenen koşullar çerçevesinde uygulamayı kullanmanız için sınırlı, devredilemez, iptal edilebilir bir lisans verir.
        </p>

        <p>
          <strong>2. Abonelik ve Ödeme</strong><br />
          Uygulama, haftalık ve aylık abonelik planları ("Abonelik Planı") sunmaktadır. Abonelik planına kaydolarak aşağıdaki şartları kabul etmiş olursunuz:<br />
          - **Faturalandırma**: Abonelik ücretleri, kullandığınız platforma (örneğin, Apple App Store) yansıtılacaktır. <br />
          - **Ödeme**: Ödemeler peşin alınır ve iade edilemez, aksi durumlar geçerli yasalara tabi olabilir. <br />
          - **Yenileme**: Abonelikler, otomatik olarak yenilenir. Yenilemeyi iptal etmek için mevcut dönemin bitiminden en az 24 saat önce iptal etmeniz gerekir. Aboneliğinizi, App Store hesabınızda otomatik yenilemeyi kapatarak yönetebilirsiniz. <br />
          - **İptal**: Aboneliğinizi istediğiniz zaman iptal edebilirsiniz. İptal işlemi sonrası, mevcut dönemin bitimine kadar abonelik avantajlarından faydalanmaya devam edebilirsiniz. İptal etmek için App Store hesabınızda otomatik yenilemeyi kapatın.
        </p>

        <p>
          <strong>3. Hesap Erişimi</strong><br />
          Uygulamanıza birden fazla cihazdan aynı kimlik bilgileriyle erişebilirsiniz. Yeni bir cihazda oturum açarsanız, abonelik haklarınıza ve hesap verilerinize erişmeye devam edebilirsiniz.
        </p>

        <p>
          <strong>4. Uygulama Kullanımı</strong><br />
          - Uygulama yalnızca kişisel, ticari olmayan kullanım amacıyla sağlanmaktadır. Uygulamayı yasa dışı bir şekilde veya geçerli yasa ve yönetmeliklere aykırı olarak kullanamazsınız. <br />
          - Uygulamayı tersine mühendislik, decompile etme, ayırma veya değiştirme işlemleri ile kullanamazsınız.
        </p>

        <p>
          <strong>5. Veri Toplama ve Gizlilik</strong><br />
          Kişisel bilgilerinizi toplarız ve bu bilgiler gizlilik politikamızda açıkça belirtildiği şekilde kullanılır. Kullanıcı verilerinin güvenliği için gerekli önlemleri alıyoruz.
        </p>

        <p>
          <strong>6. Bilgilerin Paylaşılması</strong><br />
          <strong>Change Yourself Funny - AI</strong>, kullanıcı bilgilerini üçüncü şahıslarla paylaşmaz, satmaz veya kiralamaz. Ancak, yasal bir gereklilik durumunda yasal otoritelerle veri paylaşımı yapılabilir.
        </p>

        <p>
          <strong>7. Verilerin Saklanması ve Silinmesi</strong><br />
          Kullanıcı verileri yalnızca uygulamanın işlevselliği için saklanır ve gerektiğinde güvenli bir şekilde silinir.
        </p>

        <p>
          <strong>8. Gizlilik Politikası Değişiklikleri</strong><br />
          Bu Sözleşme zaman zaman güncellenebilir. Herhangi bir değişiklik bu sayfada duyurulacak ve kullanıcıların devam etmesi durumunda bu değişiklikler kabul edilmiş sayılacaktır.
        </p>

        <p>
          <strong>9. Sorumluluk Reddi ve Sınırlama</strong><br />
          - Uygulama "olduğu gibi" sağlanır. Herhangi bir garanti verilmez. Uygulamanın doğruluğu veya kullanılabilirliği hakkında garanti verilmez.<br />
          - Kanunlar çerçevesinde, <strong>Change Yourself Funny - AI</strong> uygulamasının şirketi, uygulamanın kullanımından doğacak doğrudan veya dolaylı herhangi bir zarardan sorumlu tutulamaz.
        </p>

        <p>
          <strong>10. İletişim</strong><br />
          Bu Sözleşme ile ilgili herhangi bir sorunuz varsa, lütfen aşağıdaki iletişim bilgileri aracılığıyla bizimle iletişime geçin:
        </p>
        <p>
          <strong>İletişim E-posta:</strong> selmanatabek@hotmail.com
        </p>
      </div>
    </div>
  );
};

export default Changeyourselftrprivacyeula;
