import React from 'react';
import '../privacy/privacy.css';

const Changeyourselftrprivacy: React.FC = () => {
  return (
    <div className="privacy-policy">
      <div className="container">
        <h1>Gizlilik Politikası</h1>
        <p>
          Bu gizlilik politikası, <strong>Change Yourself Funny - AI</strong> uygulamasının kullanıcı gizliliğini nasıl koruduğunu açıklamaktadır. Gizlilik politikamız, Google Play Store'un gereksinimlerini karşılamayı ve kullanıcı güvenliğini sağlamayı amaçlar.
        </p>

        <p>
          <strong>Uygulama Hakkında</strong>
        </p>
        <p>
          <strong>Change Yourself Funny - AI</strong>, kullanıcıların yükledikleri fotoğrafları yapay zeka ile belirli kategorilere dönüştüren eğlenceli bir uygulamadır. Kullanıcılar, yükledikleri fotoğrafları, örneğin doktor, asker veya başka bir meslek gibi kategorilere dönüştürebilirler. Uygulama, fotoğraf yükleyen kişiyi kendi kategorisinde, örneğin kendisini doktor olarak görmesini sağlamak için yapay zeka kullanarak dönüştürür. 
        </p>

        <p>
          <strong>Toplanan Veriler</strong>
        </p>
        <p>
          <strong>Change Yourself Funny - AI</strong> uygulaması, kullanıcıların fotoğraf ve kişisel bilgilerini toplar, ancak yalnızca uygulamanın işlevselliği için gereklidir. Uygulama, kişisel verilerinizi üçüncü şahıslarla paylaşmaz ve yalnızca yapay zeka dönüşümleri için kullanır. 
        </p>

        <p>
          <strong>Bilgi Kullanımı</strong>
        </p>
        <p>
          Toplanan veriler, fotoğrafların dönüşümünü sağlamak ve kullanıcıların deneyimini geliştirmek amacıyla kullanılır. Fotoğraflar ve dönüşüm verileri, yalnızca uygulamanın özellikleri doğrultusunda işlenir ve kimlik tespiti yapılamaz.
        </p>

        <p>
          <strong>Bilgi Paylaşımı</strong>
        </p>
        <p>
          <strong>Change Yourself Funny - AI</strong>, kullanıcı verilerini üçüncü şahıslarla paylaşmaz, satmaz veya kiralamaz. Ancak, yasal gereklilik durumunda, yasal otoritelerle veri paylaşımı yapılabilir.
        </p>

        <p>
          <strong>Veri Saklama ve Silme</strong>
        </p>
        <p>
          Kullanıcı fotoğrafları ve dönüşüm verileri yalnızca uygulama içi işlevsellik için saklanır. Kullanıcı verileri, işlevsellik sona erdiğinde veya artık ihtiyaç kalmadığında güvenli bir şekilde silinir.
        </p>

        <p>
          <strong>Gizlilik Politikası Değişiklikleri</strong>
        </p>
        <p>
          Bu gizlilik politikası zaman zaman güncellenebilir. Herhangi bir değişiklik bu sayfada duyurulacaktır. Kullanıcıların politikayı düzenli olarak kontrol etmeleri önerilir.
        </p>

        <p>
          <strong>İletişim</strong>
        </p>
        <p>
          Gizlilik politikası ile ilgili herhangi bir sorunuz varsa, lütfen aşağıdaki iletişim bilgileri aracılığıyla bize ulaşın:
        </p>
        <p>
          <strong>İletişim E-posta:</strong> selmanatabek@hotmail.com
        </p>
      </div>
    </div>
  );
};

export default Changeyourselftrprivacy;
