import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/UserContext";
import "./Sidebar.css";
import avatar from "../../assets/avatar.png";

const Sidebar: React.FC = () => {
  const { user, setUser } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("user");
    setUser(null);
    navigate("/auth");
  };

  return (
    <div className="sidebar">
      <h2>Warenix</h2>
      <div className="user-info">
        <img src={avatar} alt="User Avatar" className="user-avatar" />
        <p>{user?.name}</p>
      </div>
      <ul>
        <li>
          <Link to="/">Anasayfa</Link>
        </li>
        <li>
          <Link to="/warehouses">Depolar</Link>
        </li>
        <li>
          <Link to="/employees">Çalışanlar</Link>
        </li>
        {/* <li>
          <Link to="/settings">Ayarlar</Link>
        </li> */}
        <li>
          <button onClick={handleLogout} className="logout-button">
            <i className="fa fa-sign-out" aria-hidden="true"></i>
            Çıkış yap
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
