import React from 'react';
import '../privacy/privacy.css';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="privacy-policy">
      <div className="container">
        <h1>Gizlilik Politikası</h1>
        <p>
          Bu gizlilik politikası, <strong>5 Tahmin</strong> uygulamasının kullanıcı
          gizliliğini nasıl koruduğunu açıklamaktadır. Gizlilik politikamız,
          Google Play Store'un gereksinimlerini karşılamayı ve kullanıcı
          güvenliğini sağlamayı amaçlar.
        </p>

        <p>
          <strong>Uygulama Hakkında</strong>
        </p>
        <p>
          <strong>5 Tahmin</strong>, kullanıcıların sırayla gelen sorulara en popüler 5
          cevabı tahmin etmesini gerektiren eğlenceli bir bilgi yarışması
          uygulamasıdır. Uygulama herhangi bir giriş işlemi gerektirmez ve
          kişisel kullanıcı verilerini toplamaz.
        </p>

        <p>
          <strong>Toplanan Veriler</strong>
        </p>
        <p>
          <strong>5 Tahmin</strong> uygulaması, herhangi bir kişisel veya hassas
          kullanıcı verisi (örneğin, e-posta, şifre, telefon numarası) toplamaz.
          Ancak uygulama kullanım deneyimini geliştirmek için anonim kullanım
          verileri (örneğin, oyun başarı oranları veya soruların yanıtlanma
          süresi gibi) toplanabilir. Bu veriler kimlik tespiti yapılamaz ve
          tamamen anonimdir.
        </p>

        <p>
          <strong>Bilgi Kullanımı</strong>
        </p>
        <p>
          Toplanan anonim veriler, oyun deneyimini iyileştirmek, uygulamanın
          performansını artırmak ve kullanıcı eğilimlerini analiz etmek için
          kullanılır.
        </p>

        <p>
          <strong>Bilgi Paylaşımı</strong>
        </p>
        <p>
          <strong>5 Tahmin</strong>, anonim veriler dahil hiçbir kullanıcı bilgisini
          üçüncü şahıslarla paylaşmaz, satmaz veya kiralamaz. Ancak, yasal
          gereklilik durumunda, yasal otoritelere veri paylaşımı yapılabilir.
        </p>

        <p>
          <strong>Veri Saklama ve Silme</strong>
        </p>
        <p>
          Anonim kullanım verileri yalnızca uygulama içi analiz ve geliştirme
          süreçlerinde kullanılır. Bu veriler, işlevsellik sona erdiğinde veya
          artık ihtiyaç kalmadığında güvenli bir şekilde silinir.
        </p>

        <p>
          <strong>Gizlilik Politikası Değişiklikleri</strong>
        </p>
        <p>
          Bu gizlilik politikası zaman zaman güncellenebilir. Herhangi bir
          değişiklik bu sayfada duyurulacaktır. Kullanıcıların politikayı
          düzenli olarak kontrol etmesi önerilir.
        </p>

        <p>
          <strong>İletişim</strong>
        </p>
        <p>
          Gizlilik politikası ile ilgili herhangi bir sorunuz varsa, lütfen
          aşağıdaki iletişim bilgileri aracılığıyla bize ulaşın:
        </p>
        <p>
          <strong>İletişim E-posta:</strong> selmanatabek@hotmail.com
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
