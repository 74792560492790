import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarcode,
  faUser,
  faCalendarAlt,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import MainService from "../../services/main_service";
import { Modal, Button, Table, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./warehouse_detail.css";
import * as XLSX from "xlsx";
import product_icon from "../../assets/product.png";
import decrease_icon from "../../assets/decrease.png";
import barcode_icon from "../../assets/barcode_read.png";

const WarehouseDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [warehouse, setWarehouse] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortColumn, setSortColumn] = useState<string>("product_name");
  const [sortDirection, setSortDirection] = useState<string>("asc");
  const productsPerPage = 10;
  const [todayOperationsCount, setTodayOperations] = useState(0);

  useEffect(() => {
    const fetchWarehouse = async () => {
      const data = {
        operation_type: "select",
        service_type: "warehouse_detail",
        warehouse_id: id,
      };
      try {
        const response = await MainService.postData(data);
        if (response.error) {
          setError(response.message);
        } else {
          setWarehouse(response.data);
          console.log(response.data.recent_operations);

          const startOfToday = new Date();
          startOfToday.setHours(0, 0, 0, 0); // Set the time to 00:00:00

          let todayOperationsCount = 0;
          for (
            let index = 0;
            index < response.data.recent_operations.length;
            index++
          ) {
            const operation = response.data.recent_operations[index];
            if (new Date(operation.date_time) >= startOfToday) {
              todayOperationsCount++;
              setTodayOperations(todayOperationsCount);
            }
          }
          console.log("Today's operations count:", todayOperationsCount);
        }
      } catch (error) {
        setError("Bir hata oluştu.");
      } finally {
        setLoading(false);
      }
    };

    fetchWarehouse();
  }, [id]);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const formatDateTime = (dateTime: string) => {
    const date = new Date(dateTime);
    const optionsDate: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    const optionsTime: Intl.DateTimeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
    };
    return `${date.toLocaleDateString(
      "tr-TR",
      optionsDate
    )} ${date.toLocaleTimeString("tr-TR", optionsTime)}`;
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Arama yapıldığında sayfayı başa al
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : 1));
  };

  const handleSortChange = (event: any) => {
    const [column, direction] = event.target.value.split(",");
    setSortColumn(column);
    setSortDirection(direction);
  };

  const paginate = (items: any[], pageNumber: number, itemsPerPage: number) => {
    const startIndex = (pageNumber - 1) * itemsPerPage;
    return items.slice(startIndex, startIndex + itemsPerPage);
  };

  const sortProducts = (items: any[]) => {
    return items.sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) {
        return sortDirection === "asc" ? -1 : 1;
      }
      if (a[sortColumn] > b[sortColumn]) {
        return sortDirection === "asc" ? 1 : -1;
      }
      return 0;
    });
  };
  // Import xlsx

  const exportLowStockProducts = () => {
    console.log("sss");
    if (!warehouse || !warehouse.low_stock_products) return;

    // Define custom column headers
    const headers = {
      name: "Ürün Adı",
      barcode: "Barkod",
      stock: "Stok",
      safety_stock: "Güvenlik Stoku",
      min_order_amount: "Minimum Sipariş Miktarı", // Updated column header
    };

    // Map the data to include the custom headers
    const dataWithCustomHeaders = warehouse.low_stock_products.map(
      (item: any) => {
        return {
          [headers.name]: item.name,
          [headers.barcode]: item.barcode,
          [headers.stock]: item.stock,
          [headers.safety_stock]: item.safety_stock,
          [headers.min_order_amount]: item.min_order_amount, // Updated data field
        };
      }
    );

    // Convert data to a worksheet
    const ws = XLSX.utils.json_to_sheet(dataWithCustomHeaders, {
      header: Object.values(headers),
    });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Azalan Stoklar");

    // Write the file
    XLSX.writeFile(wb, "Azalan_Stoklar.xlsx");
  };

  if (loading) {
    return <div className="message">Yükleniyor...</div>;
  }

  if (error) {
    return <div className="message">{error}</div>;
  }

  if (!warehouse) {
    return <div className="message">Depo bulunamadı.</div>;
  }

  // Arama işlemi ürün adları ve barkodların başlangıcına göre yapılacak şekilde güncellendi
  const filteredProducts = warehouse.global_info.filter(
    (info: any) =>
      info.product_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      info.barcode.toLowerCase().startsWith(searchTerm.toLowerCase())
  );
  const InfoCard = ({
    title,
    icon,
    data,
    backgroundColor,
    onClick,
  }: {
    title: any;
    icon: any;
    data: any;
    backgroundColor?: string;
    onClick?: () => void;
  }) => {
    return (
      <div
        className="card clickable-div"
        style={{ backgroundColor }}
        onClick={onClick}
      >
        <div
          style={{
            // display: "flex",
            // flexDirection: "row",
            // justifyContent: "space-between",
            // alignItems: "center",
          }}
        >
          <div className="icon">
            <img height={50} src={icon} alt={title} />
            <p className="poppins-thin">{title}</p>
          </div>
          <div>
            <h2>{data}</h2>
          </div>
        </div>
      </div>
    );
  };

  const sortedProducts = sortProducts(filteredProducts);

  const paginatedProducts = paginate(
    sortedProducts,
    currentPage,
    productsPerPage
  );

  const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

  const emptyRows = productsPerPage - paginatedProducts.length;

  return (
    <div className="warehouse-detail">
      <div className="header-container">
        <h3 style={{ marginRight: "3rem" }} className="poppins-thin">
          Son işlemler
        </h3>
        {/* <Button className="button-gradient" onClick={handleShowModal}>
          Tümünü Göster
        </Button> */}
      </div>

      <div className="operation-list">
        {warehouse.recent_operations
          .slice(0, warehouse.recent_operations.length)
          .map((operation: any) => (
            <div
              key={operation.id}
              className={`operation-card ${operation.type}`}
            >
              <div
                className={`operation-arrow ${operation.type}-operation-type`}
              >
                {operation.type === "accept" ? "⬆" : "⬇"}
              </div>
              <div className="operation-amount">{operation.product_name}</div>
              <div className="operation-amount">{operation.amount} adet</div>
              <div className="operation-info">
                <p>
                  <FontAwesomeIcon icon={faBarcode} /> {operation.barcode}
                </p>
                <p>
                  <FontAwesomeIcon icon={faUser} /> {operation.name}
                </p>
                <p>
                  <FontAwesomeIcon icon={faCalendarAlt} />{" "}
                  {formatDateTime(operation.date_time)}
                </p>
              </div>
            </div>
          ))}
      </div>

      {/* Modal Component */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        size="lg"
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <Modal.Title>Son İşlemler</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table-responsive">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Ürün Adı</th>
                  <th>Miktar</th>
                  <th>Barkod</th>
                  <th>İşlemi Yapan</th>
                  <th>Tarih</th>
                  <th>Tür</th>
                </tr>
              </thead>
              <tbody>
                {warehouse.recent_operations.map((operation: any) => (
                  <tr key={operation.id} className={operation.type}>
                    <td>{operation.product_name}</td>
                    <td>{operation.amount}</td>
                    <td>{operation.barcode}</td>
                    <td>{operation.name}</td>
                    <td>{formatDateTime(operation.date_time)}</td>
                    <td
                      style={{
                        backgroundColor:
                          operation.type === "accept" ? "green" : "red",
                      }}
                    >
                      {operation.type === "accept" ? "Giriş" : "Çıkış"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Kapat
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="cards-container mt-5">
        <InfoCard
          onClick={()=>{}}
          title="Tüm Ürünler"
          icon={product_icon}
          data={warehouse.global_info.length}
          backgroundColor="rgb(255 179 196 / 39%)"
        />
        <InfoCard
          onClick={exportLowStockProducts}
          title="Azalan Ürünler"
          icon={decrease_icon}
          data={warehouse.low_stock_products.length}
          backgroundColor="rgb(255 139 126 / 99%)"
        />
        <InfoCard
          onClick={handleShowModal}
          title="Bugün yapılan işlemler"
          icon={barcode_icon}
          data={todayOperationsCount}
          backgroundColor="rgb(155 179 226 / 99%)"
        />
      </div>
      {/* New Table for Global Info */}
      <h3 style={{ marginTop: "3rem" }} className="poppins-thin">
        Ürünler
      </h3>

      <div className="global-info-container">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        ></div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignSelf: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            className="pagination"
          >
            <Form.Control
              type="text"
              placeholder="Ürün Adı veya Barkod ara..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="search-input"
            />
            {/* <Button
              onClick={exportLowStockProducts}
              style={{
                marginLeft: 10,
                paddingLeft: 20,
                paddingRight: 20,
                height: "auto",
                lineHeight: "1.5",
              }}
            >
              Azalan Stokları İndir
            </Button> */}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {/* <p>Sırala:</p> */}
            <Form.Select
              aria-label="Sırala"
              onChange={handleSortChange}
              style={{ marginLeft: 10 }}
            >
              <option value="product_name,asc">Ürün Adı (A-Z)</option>
              <option value="product_name,desc">Ürün Adı (Z-A)</option>
              <option value="stock,asc">Stok (Artan)</option>
              <option value="stock,desc">Stok (Azalan)</option>
              <option value="barcode,asc">Barkod (A-Z)</option>
              <option value="barcode,desc">Barkod (Z-A)</option>
            </Form.Select>
          </div>
        </div>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Ürün Adı</th>
              <th>Barkod</th>
              <th>Stok</th>
              <th>Güvenlik Stoku</th>
            </tr>
          </thead>
          <tbody>
            {paginatedProducts.map((info: any, index: number) => (
              <tr key={info.id}>
                <td>{info.product_name}</td>
                <td>{info.barcode}</td>
                <td>{info.stock}</td>
                <td>{info.safety_stock}</td>
              </tr>
            ))}
            {emptyRows > 0 &&
              Array.from({ length: emptyRows }).map((_, index) => (
                <tr key={`empty-row-${index}`}>
                  <td colSpan={4}>&nbsp;</td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div className="pagination">
          <Button
            variant="primary"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className="pagination-button"
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
          <span className="page-info">
            {currentPage} / {totalPages}
          </span>
          <Button
            variant="primary"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="pagination-button"
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WarehouseDetail;
