import React, { useEffect, useState } from "react";
import "./Employees.css";
import { useAuth } from "../../context/UserContext";
import main_service from "../../services/main_service";


const Employees: React.FC = () => {
  const isUnderConstruction = false; // Yapım aşamasında olup olmadığını kontrol eden değişken
  const { user } = useAuth();
  const [employees, setEmployees]=useState([]);
  const getEmployees = async () => {
    const data = {
      "operation_type":"select",
      "service_type":"company_employees",
      "company_id":user.company_id
    }
    console.log(data);
    const result =await main_service.postData(data);
    console.log(result);
if(result.error === false ){
  setEmployees(result.data);
}
  }
  useEffect(()=>{
    getEmployees();
  },[])
  return (
    <div className="employees">
      {isUnderConstruction ? (
        <div className="under-construction">
          <h2>Bu Sayfa Yapım Aşamasında</h2>
          <p>
            Bu sayfa şu anda yapım aşamasındadır. Lütfen daha sonra tekrar
            kontrol edin.
          </p>
        </div>
      ) : (
        employees.map((employee, index) => (
          <div key={index} className="employee-card">
            <img
              src={require("../../assets/avatar.png")}
              alt={employee['name']}
              className="employee-avatar"
            />
            <h3>{employee['name']}</h3>
   
          </div>
        ))
      )}
    </div>
  );
};

export default Employees;
