import React from "react";
import "./Support.css";

const Support: React.FC = () => {
  return (
    <div className="support">
      <h1>Destek</h1>
      <p>Yardıma mı ihtiyacınız var? Size yardımcı olmak için buradayız! Aşağıdaki bilgilerden bizimle iletişime geçebilirsiniz:</p>
      <ul>
        <li>E-posta: support@top5.com</li>
        <li>Çalışma Saatleri: Pazartesi-Cuma, 09:00 - 18:00</li>
      </ul>
      <h2>SSS</h2>
      <p>Hızlı çözümler için <a href="/faq">Sıkça Sorulan Sorular</a> sayfamızı ziyaret edin.</p>
    </div>
  );
};

export default Support;
