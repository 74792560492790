import React, { useState, useEffect } from "react";
import "./Auth.css";
import googlePlayIcon from "../../assets/playstore-icon.png";
import appStoreIcon from "../../assets/appstore-icon.png";
import MainService from "../../services/main_service";
import { useAuth } from "../../context/UserContext";
import { useNavigate } from 'react-router-dom';

const Auth: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { setUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
      navigate('/');
    }
  }, [navigate, setUser]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const data = {
      operation_type: "select",
      service_type: "company_login",
      email: email,
      password: password,
    };

    try {
      const response = await MainService.postData(data);
      console.log("Giriş Yanıtı:", response);
      if (!response.error) {
        localStorage.setItem("user", JSON.stringify(response.data));
        setUser(response.data);
        navigate('/'); // Giriş başarılı olduğunda ana sayfaya yönlendirin
      } else {
        console.error("Giriş Hatası:", response.message);
      }
    } catch (error) {
      console.error("Giriş Hatası:", error);
    }
  };

  return (
    <div className="auth">
      <div className="auth-container">
        <div className="auth-form">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="email">E-mail</label>
              <input
                placeholder="E-postanızı girin"
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Şifre:</label>
              <input
                placeholder="Şifrenizi girin"
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit">Giriş</button>
          </form>
        </div>

        <div className="auth-info">
          <h2>Warenix</h2>
          <p>Kayıt olmak için lütfen uygulamamızı indirin</p>
          <div className="app-links">
            <a
              href="https://play.google.com/store/apps/details?id=com.warenix&hl=tr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={googlePlayIcon} alt="Google Play Store" />
            </a>
            <a
              href="https://apps.apple.com/us/app/warenix/id6474267939"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={appStoreIcon} alt="App Store" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
