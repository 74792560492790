// Örnek bir POST isteği gönderme fonksiyonu

class MainService {
    //'http://192.168.1.101:3306/barkod/service.php',
    postData = async (data: any) => {
      try {
        const response = await fetch('https://warenix.site/service.php', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        });
  
        const responseData = await response.json();
        return responseData; // Sunucudan dönen veriyi alır
      } catch (error) {
        console.error('Hata:', error);
      }
    };
  }
  
  export default new MainService();
  