import React from 'react';
import './Content.css';
import { Line } from 'react-chartjs-2'; // Örnek olarak Chart.js kullanabilirsiniz
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

// Chart.js'i yapılandır
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Content: React.FC = () => {
  const isUnderConstruction = true; // Yapım aşamasında olup olmadığını kontrol eden değişken

  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
    datasets: [
      {
        label: 'Sales',
        data: [30, 50, 40, 60, 70, 90, 80],
        borderColor: 'rgba(75,192,192,1)',
        backgroundColor: 'rgba(75,192,192,0.2)',
      },
    ],
  };

  return (
    <div className="content">
      {isUnderConstruction ? (
        <div className="under-construction">
          <h2>Bu Sayfa Yapım Aşamasında</h2>
          <p>Bu sayfa şu anda yapım aşamasındadır. Lütfen daha sonra tekrar kontrol edin.</p>
        </div>
      ) : (
        <>
          <div className="header">
            <h2>Dashboard</h2>
            <p>Overview of key metrics</p>
          </div>

          <div className="cards-container">
            <div className="card">
              <h3>Total Sales</h3>
              <p>$12,345</p>
            </div>
            <div className="card">
              <h3>New Users</h3>
              <p>234</p>
            </div>
            <div className="card">
              <h3>Revenue</h3>
              <p>$4,567</p>
            </div>
            <div className="card">
              <h3>Orders</h3>
              <p>456</p>
            </div>
          </div>

          <div className="chart-container">
            <h3>Sales Over Time</h3>
            <Line data={data} />
          </div>
        </>
      )}
    </div>
  );
};

export default Content;
