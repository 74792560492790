import React from 'react';
import '../privacy/privacy.css';

const Changeyourselfenprivacyeula: React.FC = () => {
  return (
    <div className="privacy-policy">
      <div className="container">
        <h1>End User License Agreement (EULA)</h1>
        
        <p>
          This End User License Agreement (hereinafter referred to as the "Agreement") is a legal agreement between you (<strong>User</strong>, <strong>You</strong>, or <strong>Account Holder</strong>) and the <strong>Change Yourself Funny - AI</strong> application regarding the use of the application. By downloading, installing, or using the application, you agree to the terms of this Agreement.
        </p>

        <p>
          <strong>1. License Grant</strong><br />
          The company grants you a limited, non-transferable, revocable license to use the application solely for personal, non-commercial use and in accordance with the terms of this Agreement.
        </p>

        <p>
          <strong>2. Subscription and Payment</strong><br />
          The application offers weekly and monthly subscription plans ("Subscription Plan"). By subscribing to the Subscription Plan, you agree to the following terms:<br />
          - **Billing**: Subscription fees will be reflected to the platform you use (e.g., Apple App Store).<br />
          - **Payment**: Payments are made upfront and are non-refundable, unless required by applicable law.<br />
          - **Renewal**: Subscriptions will be automatically renewed. To cancel the renewal, you must cancel at least 24 hours before the end of the current period. You can manage your subscription by turning off automatic renewal in your App Store account.<br />
          - **Cancellation**: You can cancel your subscription at any time. After cancellation, you will continue to benefit from the subscription until the end of the current period. To cancel, turn off automatic renewal in your App Store account.
        </p>

        <p>
          <strong>3. Account Access</strong><br />
          You can access your application from multiple devices using the same credentials. If you log in on a new device, you can continue to access your subscription benefits and account data.
        </p>

        <p>
          <strong>4. Application Usage</strong><br />
          - The application is provided solely for personal, non-commercial use. You may not use the application in any unlawful way or in violation of applicable laws and regulations.<br />
          - You may not reverse-engineer, decompile, disassemble, or modify the application.
        </p>

        <p>
          <strong>5. Data Collection and Privacy</strong><br />
          We collect personal information, and such information is used as specified in our Privacy Policy. Necessary measures are taken to ensure the security of user data.
        </p>

        <p>
          <strong>6. Data Sharing</strong><br />
          <strong>Change Yourself Funny - AI</strong> does not share, sell, or rent user data to third parties. However, data may be shared with legal authorities if required by law.
        </p>

        <p>
          <strong>7. Data Storage and Deletion</strong><br />
          User data is stored solely for the functionality of the application and is securely deleted when no longer needed.
        </p>

        <p>
          <strong>8. Changes to the Privacy Policy</strong><br />
          This Agreement may be updated from time to time. Any changes will be announced on this page, and continued use of the application after such changes will be considered acceptance of the new terms.
        </p>

        <p>
          <strong>9. Disclaimer of Liability and Limitation</strong><br />
          - The application is provided "as is" without any warranty. No guarantees are made regarding the accuracy or usability of the application.<br />
          - To the fullest extent permitted by law, the company behind <strong>Change Yourself Funny - AI</strong> is not liable for any direct or indirect damages resulting from the use of the application.
        </p>

        <p>
          <strong>10. Contact</strong><br />
          If you have any questions regarding this Agreement, please contact us using the details below:
        </p>
        <p>
          <strong>Contact Email:</strong> selmanatabek@hotmail.com
        </p>
      </div>
    </div>
  );
};

export default Changeyourselfenprivacyeula;
