import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Warehouses.css";
import MainService from "../../services/main_service";
import { useAuth } from "../../context/UserContext";

interface Warehouse {
  id: number;
  name: string;
  total_product_count: number;
}

const Warehouses: React.FC = () => {
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const [filteredWarehouses, setFilteredWarehouses] = useState<Warehouse[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWarehouses = async () => {
      const data = {
        operation_type: "select",
        service_type: "warehouses",
        company_id: user?.company_id,
      };

      try {
        const response = await MainService.postData(data);
        if (response.error) {
          setError(response.message);
        } else {
          setWarehouses(response.data);
          setFilteredWarehouses(response.data);
          console.log(response.data);
        }
      } catch (error) {
        setError("Bir hata oluştu.");
      } finally {
        setLoading(false);
      }
    };

    fetchWarehouses();
  }, [user?.company_id]);

  useEffect(() => {
    setFilteredWarehouses(
      warehouses.filter((warehouse) =>
        warehouse.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, warehouses]);

  const handleCardClick = (id: number) => {
    navigate(`/warehouses/${id}`);
  };

  return (
    <div className="warehouses">
      <input
        type="text"
        placeholder="Depo ara..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="search-input"
      />
      {loading && <div className="message">Yükleniyor...</div>}
      {error && !loading && (
        <div className="message">
          Hiçbir depo bulunamadı. Depo oluşturmak için mobil uygulamayı kullanın
        </div>
      )}
      {!loading && !error && filteredWarehouses.length === 0 && (
        <div className="message">
          Hiçbir depo bulunamadı. Depo oluşturmak için mobil uygulamayı kullanın
        </div>
      )}
      <div className="warehouses-list">
        {filteredWarehouses.map((warehouse) => (
          <div
            key={warehouse.id}
            className="warehouse-card"
            onClick={() => handleCardClick(warehouse.id)}
          >
            <div className="warehouse-card-inner">
              <div className="warehouse-card-content">
                <h3>{warehouse.name}</h3>
                <p>{warehouse.total_product_count} Ürün</p>
              </div>
              <div className="warehouse-card-back">
                <p>Detayları Gör</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Warehouses;
