import React from "react";
import { HashRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import Content from "./components/content/Content";
import Sidebar from "./components/sidebar/Sidebar";
import Warehouses from "./components/warehouses/Warehouses";
import Employees from "./components/employees/Employees";
import Auth from "./components/auth/Auth";
import { UserProvider, useAuth } from "./context/UserContext";
import WarehouseDetail from "./components/warehouse_detail/warehouse_detail";
import PrivacyPolicy from "./components/privacy/privacy"; // Import the PrivacyPolicy component
import Support from "./components/support/Support";
import Changeyourselftrsupport from "./components/changeyourselftrsupport/changeyourselftrsupport";
import Changeyourselfensupport from "./components/changeyourselfensupport/changeyourselfensupport";
import Changeyourselftrprivacy from "./components/changeyourselftrprivacy/changeyourselftrprivacy";
import Changeyourselfenprivacy from "./components/changeyourselfenprivacy/changeyourselfenprivacy";
import Changeyourselftrprivacyeula from "./components/changeyourselftrprivacyeula/changeyourselftrprivacyeula";
import Changeyourselfenprivacyeula from "./components/changeyourselfenprivacyeula/changeyourselfenprivacyeula";

const App: React.FC = () => {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Layout />
              </ProtectedRoute>
            }
          >
            <Route index element={<Content />} />
            <Route path="warehouses" element={<Warehouses />} />
            <Route path="warehouses/:id" element={<WarehouseDetail />} />
            <Route path="employees" element={<Employees />} />
          </Route>
          <Route path="/auth" element={<Auth />} />
          <Route path="/privacy" element={<PrivacyPolicy />} /> 
          <Route path="/support" element={<Support />} /> {/* Support sayfası rotası */}
          <Route path="/changeyourselftrsupport" element={< Changeyourselftrsupport />} /> 
          <Route path="/changeyourselfensupport" element={< Changeyourselfensupport />} /> 
          <Route path="/changeyourselftrprivacy" element={< Changeyourselftrprivacy />} /> 
          <Route path="/changeyourselfenprivacy" element={< Changeyourselfenprivacy />} /> 
          <Route path="/changeyourselftrprivacyeula" element={< Changeyourselftrprivacyeula />} /> 
          <Route path="/changeyourselfenprivacyeula" element={< Changeyourselfenprivacyeula />} /> 

          

          changeyourselftrprivacyeula




         
        </Routes>
      </Router>
    </UserProvider>
  );
};

const Layout: React.FC = () => {
  return (
    <div className="app">
      <Sidebar />
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Content />} />
          <Route path="warehouses" element={<Warehouses />} />
          <Route path="warehouses/:id" element={<WarehouseDetail />} />
          <Route path="employees" element={<Employees />} />
        </Routes>
      </div>
    </div>
  );
};

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user } = useAuth();
  return user ? <>{children}</> : <Navigate to="/auth" />;
};

export default App;
